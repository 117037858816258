import React from 'react';
import { ThemeProvider } from './src/context/ThemeContext';
import "./src/components/layout.css"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
);
